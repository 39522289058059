<template>
  <div class="main-container" style="padding-right:0;padding-left:0" dir="ltr">
    <section class="bg-gray pt-4 pr-15 pl-15" style="background: rgb(255, 255, 255) none repeat scroll 0% 0%;margin-bottom: 15vh;">
      <div class="container-fluid" dir="rtl">
        <div class="row justify-content-center" >
          <div class="col-xl-12 col-md-7" v-for="publish in CourseInfo" :key="publish.id">
              <img style="display: none" class="viewtube-player-single" :src="publish.img" />
                <div class="d-flex content-creator justify-content-between" dir="rtl">
                  <div class="my-auto">
                    <a href="#">
                      <img :src="publish.img" alt=""></a>
                      <span class="pl-10">  {{publish.name_ar}}</span>
                  </div>
                  <div class="my-auto" style="color: #fff;">
                    <a class="viewtube-btn subscribe" v-if="!pauseTrack" @click="play()">
                      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 485 485" style="width: 17px;height: 17px;margin-left: 10px;enable-background:new 0 0 277.338 277.338;" xml:space="preserve" fill="#fff" version="1.1">
                          <g>
	                        <path d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5   s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026   C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5   S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"/>
	                        <polygon points="181.062,336.575 343.938,242.5 181.062,148.425  "/>
                          </g>
                      </svg> تشغيل</a>
                      <a class="viewtube-btn subscribe" v-else @click="pause()">
                        <svg  id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="277.338px" height="277.338px" viewBox="0 0 277.338 277.338" style="width: 17px;height: 17px;margin-left: 10px;enable-background:new 0 0 277.338 277.338;"  xml:space="preserve" fill="#fff" version="1.1">
                            <g>
	                            <path d="M14.22,45.665v186.013c0,25.223,16.711,45.66,37.327,45.66c20.618,0,37.339-20.438,37.339-45.66V45.665   c0-25.211-16.721-45.657-37.339-45.657C30.931,0,14.22,20.454,14.22,45.665z"/>
	                            <path d="M225.78,0c-20.614,0-37.325,20.446-37.325,45.657V231.67c0,25.223,16.711,45.652,37.325,45.652s37.338-20.43,37.338-45.652   V45.665C263.109,20.454,246.394,0,225.78,0z"/>
                            </g>
                        </svg> ايقاف</a>
                   </div>
                </div>
                <div class="entry-content">
                  <h1 class="video-entry-title">{{publish.name_ar}}</h1>
                  <p>{{publish.name_en}}</p>
                </div>
          </div>
        </div>
        <div class="row justify-content-center " dir="rtl"  data-slick='{"slidesToShow": , "slidesToScroll": }'>
          <div class="col-12">
            <h4 style="margin-top: 5px;"> كل الملفات الصوتية</h4>
          </div>
          <div @click="selectSound(indexo)" :style="indexo == index ? '' : ''" :class="indexo == index ? 'harry':''" class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="(audio,indexo) in audios" :key="indexo">
            <div class="card">
              <div class="video-item-card">
                <a >
                  <div class="card__cover">
                    <img :src="audio.img" class="custom-logo" alt="">
                    <svg  v-if="state.audioPlaying[indexo]" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="277.338px" height="277.338px" viewBox="0 0 277.338 277.338" style="width: 40px;height: 40px;margin-left: 10px;enable-background:new 0 0 277.338 277.338;"  xml:space="preserve" fill="#fff" version="1.1">
                      <g>
	                      <path d="M14.22,45.665v186.013c0,25.223,16.711,45.66,37.327,45.66c20.618,0,37.339-20.438,37.339-45.66V45.665   c0-25.211-16.721-45.657-37.339-45.657C30.931,0,14.22,20.454,14.22,45.665z"/>
	                      <path d="M225.78,0c-20.614,0-37.325,20.446-37.325,45.657V231.67c0,25.223,16.711,45.652,37.325,45.652s37.338-20.43,37.338-45.652   V45.665C263.109,20.454,246.394,0,225.78,0z"/>
                      </g>
                    </svg> 
                    <svg v-else id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 485 485" style="width: 40px;height: 40px;margin-left: 10px;enable-background:new 0 0 277.338 277.338;" xml:space="preserve" fill="#fff" version="1.1">
                      <g>
	                      <path d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5   s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026   C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5   S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"/>
	                      <polygon points="181.062,336.575 343.938,242.5 181.062,148.425  "/>
                      </g>
                    </svg>
                  </div>
                </a>
                <div class="video-content">
                  <div class="d-flex">
                    <div class="my-auto " style="width: 100%;">
                      <a href="#">
                        <h5>{{audio.title_ar}}</h5>
                      </a>
                      <ul class="list-inline" style="justify-content: center;" >
                        <li class="list-inline-item">
                          <a class="author ">{{audio.name_ar}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>                
          </div>
      </div>
    </div>
  </section>
  <template v-if="showPlayer">
  <footer class="iq-footer" >
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <div class="player row">
                  <div class="details col-6 col-sm-4 col-md-4 col-lg-4" v-for="(audio,indexo) in audios.slice(index, index + 1)" :key="indexo">
                     <div class="now-playing"></div>
                     <!-- <div class="track-art"></div> -->
                     <img :src="audio.img" class="track-art" alt="">
                     <div>
                        <div class="track-name">{{audio.desc_ar}} </div>
                        <div class="track-artist">{{audio.title_ar}}</div>
                     </div>
                  </div>
                  <div class="slider_container slider_music col-sm-5 col-md-4 col-lg-4">
                     <div class="current-time">{{timer}}</div>
                     <!-- <input type="range" class="seek_slider" @click="seek($event)" ref="progress"> -->
                      <div class="progress" @click="seek($event)" ref="progress">            
                       <div class="progress-bar bg-success " role="progressbar" style="width: 0%;" :style="{'width' : step + '%'}"></div>            
                      </div>
                     <div class="total-duration">{{duration}}</div>
                  </div>
                  <div class="buttons col-6  col-sm-3 col-md-2  col-lg-2">
                     <div class="prev-track" @click="prevButton ? previous() : ''"><i class="fa fa-step-backward fa-2x"></i></div>
                     <div class="playpause-track">
                       <i class="fa fa-play-circle fa-3x" v-if="!pauseTrack" @click="play()"></i>
                        <!-- <i class="fa fa-play-circle fa-3x" ></i> -->
                       <i class="fa fa-pause-circle fa-3x" v-else @click="pause()"></i>
                      </div>
                     <div class="next-track" @click="nextButton ? next() : ''"><i class="fa fa-step-forward fa-2x"></i></div>
                  </div>
                  <div class="slider_container sound col-sm-6 col-md-2  col-lg-2">
                     <i class="fa fa-volume-down" ></i>
                     <input type="range" class="volume_slider" @click="volume($event)" ref="volBar">
                     <i class="fa fa-volume-up"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
  </template>
  <template v-else>
    <app-footer/>
  </template>
   <!-- <footer class="iq-footer" >
      
   </footer> -->
</div>

</template>

<script>
import config from '@/Api/config';
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { reactive, ref } from '@vue/reactivity';
import { Howl, Howler } from 'howler';
import { watch, watchEffect } from '@vue/runtime-core';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import AppFooter from '../layout/AppFooter.vue';
export default {
  components: { AppFooter },
  props: {
      series_id: String
  },
  name:'AppSeriesContent',
  mounted(){ 
    if(this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
      var sound = this.audios[this.index].howl;
      var barWidth = (0.9 * 100) / 100;
      this.sliderBtnVol = (this.volBar.offsetWidth * barWidth + this.volBar.offsetWidth * 0.05 - 25);
    }
    },

    async setup(props) {
     const router = useRouter();
     const route = useRoute();
    const cookie = useCookie();
    // const toast = useToast();
    const carousel = ref([]);
    const CourseInfo = ref([]);
    const CourseContent = ref([]);
    const showPlayer = ref(true);


    const audios = ref([]);
      const step =  ref(0);
      const nextButton = ref(true);
      const prevButton = ref(true);
      const random = ref(false);
      const repeat = ref(false);
      const index = ref(0);
      const duration = ref('00:00');
      const timer = ref('00:00');
      const pauseTrack = ref(false);
      const progress = ref(null);
      const volBar = ref(null);
      const sliderBtn = ref(0);
      const sliderBtnVol = ref(null);
      const volumeProgress = ref(90);
      const mutePlayer = ref(false);
      const state = reactive({
        audioPlaying: []
      })
     watchEffect(() => {
            if (cookie.isCookieAvailable("msisdn")) {
                HTTPDSP.post("DSPCheckLogin.php?msisdn="+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return;
                    } else {
                        cookie.removeCookie('msisdn');
                    }
                });
                showPlayer.value = true;
            } else {
               showPlayer.value = false;
            }
        }); 


    try {
      await HTTP.get(`GetCourse.php?ID=${props.series_id}`,{
          headers: {
            'Authorization': config.authHeader,
          },
      }).then((res) => {
        CourseInfo.value = res.data.CourseInfo; 
      });
    } catch (err) {
      console.log(err);
    }
    try {
      await HTTP.get(`GetCategoryContent.php?LIMIT=250&OFFSET=0&cat_id=${props.series_id}`,{
          headers: {
            'Authorization': config.authHeader,
          },
      }).then((res) => {
        audios.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }



        function formatTime(secs) {
          var minutes = Math.floor(secs / 60) || 0;
          var seconds = Math.floor(secs - minutes * 60) || 0;
          return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        }

        function play() {
          if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
        
            var sound;
            var audio = audios.value[index.value];
            if (audio.howl) {
                sound = audio.howl
            }else{
                state.audioPlaying[index.value] = false;
                sound = audio.howl =  new Howl({
                src: [audio.source],
                html5: true, // A live stream can only be played through HTML5 Audio.
                format: ['mp3', 'aac'],
                onplay: function(){
                    pauseTrack.value = true; 
                    nextButton.value = true;
                    prevButton.value = true;
                    duration.value = formatTime(sound.duration());
                    requestAnimationFrame(stepFunction.bind(this));
                },
                onpause: function(){
                    pauseTrack.value = false;
                },
                onend: function() {
                    // next();
                    pauseTrack.value = false;
                },
                onseek: function(){
                  window.requestAnimationFrame(stepFunction.bind(this));
                }
                });
            }
            sound.play();
            state.audioPlaying[index.value] = true;
            } else {
               window.open("https://dsplp.sd.zain.com/?p=2847968194", "_self");
          }
        }

        function pause(indexo) {
            
            var audio = audios.value[index.value].howl;
            if (audio) {
                audio.pause();
                pauseTrack.value = false; 
                state.audioPlaying[index.value] = false;   
            }
        }

        function stepFunction() {
            var sound = audios.value[index.value].howl;
            var seek = sound.seek();
            timer.value = formatTime(Math.round(seek)); 
            step.value = (seek * 100) / sound.duration();
            sliderBtn.value = (progress.value.offsetWidth * (step.value/100) + progress.value.offsetWidth * 0.05 - 25);
            if (sound.playing()) {
                window.requestAnimationFrame(stepFunction.bind(this));

            }
        }

        function seek(event) {
            var per =  event.offsetX / progress.value.clientWidth; 
            var sound = audios.value[index.value].howl;
            if (sound) {
                if (sound.playing()) {
                    sound.pause();
                    sound.seek(sound.duration() * per);
                    var barWidth = (per * 100) / 100;
                    sliderBtn.value = (progress.value.offsetWidth * barWidth + progress.value.offsetWidth * 0.05 - 25);
                    sound.play();  
                }else{
                    sound.seek(sound.duration() * per);
                    // var barWidth = (per * 100) / 100;
                    sliderBtn.value = (progress.value.offsetWidth * barWidth + progress.value.offsetWidth * 0.05 - 25);
                }                
            }
        }

        function next() {

            nextButton.value = false;
            var audio = audios.value[index.value].howl;
            state.audioPlaying[index.value] = false;
            mutePlayer.value ? mutePlayer.value = false : '';
            audio && audio.mute(true) ?  audio.mute(false) : '';
            
            if ( audio && audios.value.length - 1 ==  index.value) {
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value = 0
            } else {    
                if (audio) {
                    audio.stop();
                }
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length) 
                :  
                index.value ++;                 
            }
            play();
        } 

        function previous() {

            var audio = audios.value[index.value].howl;
            prevButton.value = false;
            state.audioPlaying[index.value] = false;

            mutePlayer.value ? mutePlayer.value = false : '';
            audio && audio.mute(true) ?  audio.mute(false) : '';

            if (!audio) {
                index.value = audios.value.length - 1;
            } else if (audio && index.value == 0) {
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value = audios.value.length - 1;              
            } else if(audio) {    
                            
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value --;
            } 
            play();
        }

        function selectSound(indexSelected) {

            var audio = audios.value[index.value].howl;
            if (audio) {
                audio.stop();
                state.audioPlaying[index.value] = false;
            }
            index.value = indexSelected;
            play();
        }

        function volume(event) {
    
            var per = event.layerX / parseFloat(volBar.value.scrollWidth);
            var barWidth = (per * 100) / 100;
            volumeProgress.value = barWidth * 100;
            sliderBtnVol.value = (volBar.value.offsetWidth * barWidth + volBar.value.offsetWidth * 0.05 - 25);
            Howler.volume(per);
        }

        function mute() {
            
            var audio = audios.value[index.value].howl;
            if (audio) {
               mutePlayer.value  = !mutePlayer.value;
               mutePlayer.value ? audio.mute(true) : audio.mute(false)
            }
        }

        watch(() => route,(to, from) => {
          pause();
        // console.log(`${previous} and ${current}`);
        // window.scrollTo(0, 0);
      },
      { deep: true }
    );


   
    const GoToContent = (publish) => {
        router.push({ name: "Contents", params: { content_id: publish.id } });
    };
    
    return { carousel, CourseInfo, CourseContent ,GoToContent, 
          play,pause,duration,formatTime,audios,pauseTrack,next,previous,
          index,timer,step,stepFunction,seek,selectSound,state,random,repeat,
          progress,volume,volBar,volumeProgress,sliderBtn,mute,mutePlayer,
          sliderBtnVol,nextButton,prevButton, showPlayer
          };
    
  },

}
</script>

<style >
   .progress{
  min-width: 250px;
  height: 8px;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  /* background: rgba(0,0,0,0.06) */
}
 .progress-bar{
  opacity: 0.8;
}

.seek_slider, .volume_slider {
  background: #fff;
}

.harry {
  color: red;
}
</style>